/* src/crossword.css */

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --primary-color: #4a4a4a;
  --secondary-color: #757575;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --border-color: #e0e0e0;
  --input-background: #ffffff;
  --cell-border-color: #000;
  --blocked-cell-color: #000;
  --highlighted-cell-color: #e6f3ff;
  --current-cell-color: #b3d9ff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1,
h2 {
  text-align: center;
  color: var(--primary-color);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
}

.create-room,
.join-room {
  margin-top: 40px;
  padding: 30px;
  background-color: var(--input-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.form-group input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

label {
  margin-bottom: 15px;
  font-weight: 500;
}

button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: var(--secondary-color);
}

.crossword-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 10px; /* Add some horizontal padding */
}

.crossword-grid-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding-bottom: 20px;
  width: 100%;
  max-width: 600px; /* Match this with the crossword-grid max-width */
  margin: 0 auto;
}

.crossword-grid {
  display: grid;
  grid-gap: 1px;
  background-color: var(--cell-border-color);
  padding: 1px;
  border: 2px solid var(--cell-border-color);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  aspect-ratio: 1 / 1; /* Ensure the grid remains square */
}

.crossword-cell {
  position: relative;
  background-color: var(--input-background);
  overflow: visible;
  cursor: pointer;
  aspect-ratio: 1 / 1;
}

.crossword-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.crossword-cell input:focus {
  outline: none;
}

.crossword-cell.blocked-cell {
  background-color: var(--blocked-cell-color);
}

.crossword-cell.highlighted-cell {
  background-color: var(--highlighted-cell-color);
}

.crossword-cell.current-cell {
  background-color: var(--current-cell-color);
}

.crossword-cell.current-cell.horizontal::after,
.crossword-cell.current-cell.vertical::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.crossword-cell.current-cell.horizontal::after {
  border-bottom: 2px solid var(--primary-color);
}

.crossword-cell.current-cell.vertical::after {
  border-left: 2px solid var(--primary-color);
}

.clue-number {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 0.6em;
  color: var(--secondary-color);
  z-index: 1;
  user-select: none;
}

.user-cursor-indicator__background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-cursor-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;
}

.user-cursor-indicator__initials {
  font-size: 10px;
  color: white;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 50;
  user-select: none;
  background-color: var(--secondary-color);
}

.room-container {
  max-width: 800px;
  margin: 0 auto;
}

.room-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.room-header h1 {
  margin: 0;
  font-size: 28px;
}

.user-list {
  display: flex;
  gap: 10px;
}

.user-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--secondary-color);
}

.user-you {
  font-size: 14px;
  color: var(--secondary-color);
}

@media (max-width: 600px) {
  .App {
    padding: 20px 10px;
  }

  .create-room,
  .join-room {
    padding: 20px;
  }
}

.room-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.room-header__left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.room-header__right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-list {
  display: flex;
  gap: 10px;
}

.user-indicator {
  display: flex;
  align-items: center;
}

.user-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.room-controls {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

.btn-secondary {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--border-color);
}

.setup-instructions {
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .room-header__right {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .room-controls {
    width: 100%;
  }

  .btn {
    flex: 1;
  }
}

.join-room-form {
  background-color: var(--input-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.initials-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;
}

.initials-input:focus {
  outline: none;
  border-color: var(--primary-color);
}
.virtual-keyboard {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
}

.keyboard-key {
  font-size: 16px;
  font-weight: bold;
  padding: 16px 8px;
  margin: 0 2px;
  border: none;
  border-radius: 4px;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  flex-grow: 1;
  max-width: 40px;
}

.keyboard-key:hover {
  background-color: var(--primary-color);
}

.keyboard-key:active {
  background-color: var(--text-color);
}

@media (max-width: 770px) {
  .virtual-keyboard {
    display: block;
  }
  .crossword-grid {
    margin-bottom: 200px
  }
}
